import { defineStore } from 'pinia'

import type { PaymentStep, PaymentScreen, PaymentBundle, PaymentEntry } from '../types'

type InvoiceState = {
  step: PaymentStep
  bundle: PaymentBundle
  entry: PaymentEntry
  entryPath: string
  type: string
  invoiceId: string
  invoiceStatus: string
}

type StoreState = {
  hasPurchase: boolean
  subscriptionActive: boolean
  showPayment: boolean
  screen: PaymentScreen
} & InvoiceState

const SESSION_STORAGE_KEY = 'payment-invoice-state'

export const usePaymentStore = defineStore('payment', {
  state: (): StoreState => {
    return {
      hasPurchase: false,
      subscriptionActive: false,
      showPayment: false,
      screen: '',
      step: '',
      bundle: '',
      entry: '',
      entryPath: '',
      type: '',
      invoiceId: '',
      invoiceStatus: '',
    }
  },
  actions: {
    openPayment(screen: PaymentScreen = 'base_bundles') {
      this.screen = screen
      this.step = ''
      this.bundle = ''
      this.entry = ''
      this.entryPath = ''
      this.type = ''
      this.invoiceId = ''
      this.invoiceStatus = ''
      this.showPayment = true
    },
    closePayment() {
      this.showPayment = false
    },
    cacheInit() {
      const cache = sessionStorage.getItem(SESSION_STORAGE_KEY)
      if (!cache) return
      const state = JSON.parse(cache) as InvoiceState
      if (state.step) this.step = state.step
      if (state.bundle) this.bundle = state.bundle
      if (state.entry) this.entry = state.entry
      if (state.entryPath) this.entryPath = state.entryPath
      if (state.type) this.type = state.type
      if (state.invoiceId) this.invoiceId = state.invoiceId
      if (state.invoiceStatus) this.invoiceStatus = state.invoiceStatus
    },
    cacheSave() {
      const defaultValues: InvoiceState = {
        step: this.step,
        bundle: this.bundle,
        entry: this.entry,
        entryPath: this.entryPath,
        type: this.type,
        invoiceId: this.invoiceId,
        invoiceStatus: this.invoiceStatus,
      }
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(defaultValues))
    },
    cacheClear() {
      sessionStorage.removeItem(SESSION_STORAGE_KEY)
    },
  },
})
